import type { Ref } from 'vue'
import { ref } from 'vue'
import isFunction from 'lodash/isFunction'
import type { ConfirmModalSettings } from './types'

export const isVisibleModal = ref(false)
export const title = ref<undefined | ConfirmModalSettings['title']>()
export const description = ref<undefined | ConfirmModalSettings['description']>()
export const options = ref<ConfirmModalSettings['options']>([])
export const resolvePromise = ref(undefined) as Ref<any>
export const rejectPromise = ref(undefined) as Ref<any>

export function showDynamicConfirmModal(settings: ConfirmModalSettings): Promise<any | Error> {
  title.value = settings.title
  description.value = settings.description ?? ''
  options.value = settings.options
  isVisibleModal.value = true

  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve
    rejectPromise.value = reject
  })
}

export function confirmConfirmModal(value: string) {
  if (isFunction(resolvePromise.value))
    resolvePromise.value(value)

  isVisibleModal.value = false
}

export function rejectConfirmModal() {
  if (isFunction(rejectPromise.value))
    rejectPromise.value()

  isVisibleModal.value = false
}
